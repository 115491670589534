/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, Suspense, useRef, useEffect } from 'react';
import styles from './onboarding-page.module.css';
import ReactPlayer from 'react-player'
import { Player } from '@lordicon/react';
import { CopyBlock, monoBlue } from 'react-code-blocks';
import { Buffer } from 'buffer';
import { Space, Typography, Button, Timeline, Card, Input, Spin } from 'antd';
import Segmented, { SegmentedLabeledOption } from 'antd/es/segmented';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faKey, faPaperPlane, faScaleBalanced, faSign, faSignature } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiKeySelector,
  currentClientSelector,
  secretSelector,
  setNotification,
} from 'slices/app-slice';
import { useGenerateTokenMutation } from 'slices/token-api-slice';
import { useSendAgreementMutation } from 'slices/send-agreement-api-slice';
import { Notification } from 'components/notification/notification';
const { Title, Text } = Typography;
const ICON1 = require('./assets/article.json');
const ICON2 = require('./assets/api.json');
const ICON3 = require('./assets/ai.json');

const lazyWithRetry = (componentImport) =>
  React.lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
const ContractEditorComponent = lazyWithRetry(() =>
  import('sendforsign').then((module) => ({ default: module.ContractEditor }))
);

const AIComponent = lazyWithRetry(() =>
  import('sendforsign').then((module) => ({ default: module.AiAssistant }))
);

export const OnboardingPage: React.FC = () => {
  const dispatch = useDispatch();
  const apiKey = useSelector(apiKeySelector);
  const secret = useSelector(secretSelector);
  const currentClient = useSelector(currentClientSelector);
  const [generateSpin, setGenerateSpin] = useState(false);
  const [sendSpin, setSendSpin] = useState(false);
  const [disableSendAgreement, setDisableSendAgreement] = useState(false);
  const [visibleInput, setVisibleInput] = useState(false);
  const [visibleEsign, setvisibleEsign] = useState(false);
  const [visibleApi, setvisibleApi] = useState(false);
  const [visibleLegal, setvisibleLegal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [generateToken] = useGenerateTokenMutation();
  const [sendAgreement] = useSendAgreementMutation();

  const playerRef1 = useRef<Player>(null);
  const playerRef2 = useRef<Player>(null);
  const playerRef3 = useRef<Player>(null);

  useEffect(() => {playerRef1.current?.play();}, []);
  useEffect(() => {playerRef2.current?.play();}, []);
  useEffect(() => {playerRef3.current?.play();}, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleClick = async () => {
    setGenerateSpin(true);
    const string = `${apiKey};${secret};${currentClient.clientKey}`;
    const buffer = Buffer.from(string).toString('base64');
    // console.log('string', string);
    await generateToken({ payload: buffer })
      .unwrap()
      .then((data) => {
        setGenerateSpin(false);
        // console.log('data', data);
        if (data.accessToken) {
          setInputValue(data.accessToken);
          setVisibleInput(true);
        }
      });
      scrollToBottom();
  };
  const handleSendAgreement = async () => {
    const body: {
      data: {
        action: string;
        contract: { name: string; value: string };
        recipients: [
          {
            action: string;
            customMessage: string;
            fullname: string;
            email: string;
            position: number;
          }
        ];
      };
    } = {
      data: {
        action: 'create',
        contract: {
          name: 'My first agreement',
          value: '<p>This is your first agreement. Sign it!</p>',
        },
        recipients: [
          {
            action: 'sign',
            customMessage: 'Congrats on sending your first agreement!',
            fullname: currentClient.fullname
              ? currentClient.fullname
              : currentClient.organization,
            email: currentClient.email,
            position: 1,
          },
        ],
      },
    };
    setSendSpin(true);
    await sendAgreement({
      body: JSON.stringify(body),
      token: inputValue,
    })
      .unwrap()
      .then((data) => {
        setSendSpin(false);
        if (data.result) {
          setDisableSendAgreement(true);
          dispatch(
            setNotification({
              text: 'Your first agreement has been created and sent! Check your email to open and sign it.',
            })
          );
        }
      });
  };

  const handleEsignClick = async () => {
    setvisibleEsign(true);
    setvisibleApi(false);
    setvisibleLegal(false);
    scrollToBottom();
  };

  const handleApiClick = async () => {
    setvisibleApi(true);
    setvisibleEsign(false);
    setvisibleLegal(false);
    scrollToBottom();
  };
  
  const handleLegalClick = async () => {
    setvisibleLegal(true);
    setvisibleApi(false);
    setvisibleEsign(false);
    scrollToBottom();
  };

  const handleClickBeta = async () => {
    dispatch(
      setNotification({
        text: 'Noted 👌. We will notify you when the Legal AI Copilot is ready.',
      })
    );
  };

  const handleEsignHover = async () => {
    playerRef1.current?.playFromBeginning();    
  };

  const handleApiHover = async () => {
    playerRef2.current?.playFromBeginning();    
  };

  const handleLegalHover = async () => {
    playerRef3.current?.playFromBeginning();    
  };

  return (
    <>
      <Space direction="vertical" style={{ display: 'flex' }} size={16}>

        <Card style={{ opacity: 1 }} bordered={true}>
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ display: 'flex' }}
                    >
                      <Space direction="vertical" size={2}>
                        <Title level={4} style={{ margin: '0 0 0 0' }}>
                          Where would you like to begin?
                        </Title>
                        <Text type="secondary">
                          Choose your case, we'll take care of the rest.
                        </Text>
                      </Space>     
                      <Button 
                        onClick={handleLegalClick} 
                        onMouseEnter={handleLegalHover} 
                        className={styles.btngrad3} 
                        type="primary" 
                        size="large" 
                        style={{ width: '100%', maxWidth:'300px', whiteSpace: 'normal', height: 80, lineHeight: 1.4 }}
                        icon={                      
                          <Player 
                          size={28}
                          ref={playerRef3} 
                          icon={ ICON3 }
                          colorize='white'
                        />}>
                        Write and analyze with Legal AI 
                      </Button>              
                      <Button 
                        onClick={handleApiClick} 
                        onMouseEnter={handleApiHover} 
                        className={styles.btngrad2} 
                        type="primary" 
                        size="large" 
                        style={{ width: '100%', maxWidth:'300px', whiteSpace: 'normal', height: 80, lineHeight: 1.4 }}
                        icon={                      
                          <Player 
                          size={28}
                          ref={playerRef2} 
                          icon={ ICON2 }
                          colorize='white'
                        />}>
                        Integrate with eSign API
                      </Button>
                      <Button 
                        onClick={handleEsignClick} 
                        onMouseEnter={handleEsignHover} 
                        className={styles.btngrad1} 
                        type="primary" 
                        size="large" 
                        style={{ width: '100%', maxWidth:'300px', whiteSpace: 'normal', height: 80, lineHeight: 1.4 }}
                        icon={                      
                          <Player 
                          size={28}
                          ref={playerRef1} 
                          icon={ ICON1 }
                          colorize='white'
                        />}>
                        Draft and eSign a doc 
                      </Button>
                    </Space>
                  </Card>

                  {visibleEsign && (
                  <Suspense
                        fallback={
                        <Spin spinning={true} style={{ width: '100%' }} percent={'auto'} />
                        }
                        >
                          <ContractEditorComponent
                          apiKey={apiKey}
                          clientKey={currentClient.clientKey}
                        />
                      </Suspense>
                  )}

                  {visibleLegal && (
                      <Suspense
                      fallback={
                      <Spin spinning={true} style={{ width: '100%' }} percent={'auto'} />
                      }
                      >
                        <AIComponent
                        apiKey={apiKey}
                        clientKey={currentClient.clientKey}
                      />
                    </Suspense>
                  )}

                  {visibleApi && (
                    <Card style={{ opacity: 1 }} bordered={true}>
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ display: 'flex' }}
                    >
                      <Space direction="vertical" size={2}>
                        <Title level={4} style={{ margin: '0 0 0 0' }}>
                          Create an Access Token
                        </Title>
                        <Text type="secondary">
                          Use the following generated token to authenticate this
                          request.
                        </Text>
                      </Space>
                      {visibleInput && (
                        <Input
                          readOnly
                          value={inputValue}
                          placeholder="The contract link"
                        />
                      )}
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faKey} />}
                        loading={generateSpin}
                        disabled={visibleInput}
                        onClick={handleClick}
                      >
                        Create Access Token
                      </Button>
                    </Space>
                  </Card>
                  )}

                  {visibleApi && (
                    <Card
                    bordered={true}
                    style={{ opacity: visibleInput ? 1 : 0.5 }}
                  >
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ display: 'flex' }}
                    >
                      <Space direction="vertical" size={2}>
                        <Title level={4} style={{ margin: '0 0 0 0' }}>
                          Send an agreement
                        </Title>
                        <Text type="secondary">
                          Implement or run the code below to send your first
                          agreement.
                        </Text>
                      </Space>
                      {visibleInput && (
                        <Card style={{ height: 250, overflow: 'auto', background: '#eaeef3' }}>
                          <CopyBlock
                            language="javascript"
                            text={`curl -X POST 'https://api.sendforsign.com/api/contract' \\
  -H 'Authorization: Bearer ${inputValue} \\
  -H 'Content-Type: application/json' \\
  -d '{
    "data": {
        "action": "create",
        "contract": {
            "name": "My first agreement",
            "value": "<p>This is your first agreement. Sign it!</p>"
        },
        "recipients": [
            {
                "action": "sign",
                "customMessage": "Congrats on sending your first agreement!",
                "fullname": "${
                  currentClient.fullname
                    ? currentClient.fullname
                    : currentClient.organization
                }",
                "email": "${currentClient.email}",
                "position": 1
            }
        ]
    }
  }'`}
                            codeBlock={false}
                            theme={monoBlue}
                            showLineNumbers={false}
                          />
                        </Card>
                      )}
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faPaperPlane} />}
                        disabled={!visibleInput || disableSendAgreement}
                        loading={sendSpin}
                        onClick={handleSendAgreement}
                      >
                        Send agreement
                      </Button>
                    </Space>
                  </Card>
                  )}

      </Space>
      <Notification />
    </>
  );
};

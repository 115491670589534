/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useAuth } from '@clerk/clerk-react';
import { CopyBlock, monoBlue } from 'react-code-blocks';
import { Typography, Table, Row, Col, Space, Drawer, Button, Tooltip, Card, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCopy, faPlus } from '@fortawesome/free-solid-svg-icons';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { apiKeySelector, secretSelector } from 'slices/app-slice';
import { useGetClientsQuery } from 'slices/client-api-slice';
import { useGetUsersQuery } from 'slices/subclient-api-slice';

const { Title } = Typography;

interface APIDataType {
  key: React.Key;
  name: string;
  secret?: string;
  // created: string;
}

interface ClientDataType {
  key: React.Key;
  name: string;
  email: string,
  organisation: string,
  created: string;
}

interface UserDataType {
  key: React.Key;
  name: string;
  created: string;
  clientKey?: string;
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    notification.success({ // Replace alert with notification
      message: 'Success',
      description: 'Copied to clipboard!',
      placement: 'bottomRight'
    });
  });
};

const columnsAPI: ColumnsType<APIDataType> = [
  {
    title: 'API Key',
    dataIndex: 'name',
    render: (text: string) => (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        {'***-***-***'}
        <Button size="small" onClick={() => copyToClipboard(text)} icon={<FontAwesomeIcon icon={faCopy} />} />
      </Space>
    ),
  },

  {
    title: 'Secret',
    dataIndex: 'secret',
    render: (text: string) => (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        {text}
        <Button size="small" onClick={() => copyToClipboard(text)} icon={<FontAwesomeIcon icon={faCopy} />} />
      </Space>
    ),
  },
];

const columnsClient: ColumnsType<ClientDataType> = [
  {
    title: 'Organisation',
    dataIndex: 'organisation',
  },
  {
    title: 'Client Name',
    dataIndex: 'name',
  },
  {
    title: 'Client Email',
    dataIndex: 'email',
  },
  {
    title: 'Client Key',
    dataIndex: 'key',
    width: 370, 
    render: (text: string) => (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        {text}
        <Button size="small" onClick={() => copyToClipboard(text)} icon={<FontAwesomeIcon icon={faCopy} />} />
      </Space>
    ),
  },
  {
    title: 'Created at',
    dataIndex: 'created',
  },
];

const columnsUser: ColumnsType<UserDataType> = [
  {
    title: 'User Name',
    dataIndex: 'name',
  },
  {
    title: 'User Key',
    dataIndex: 'key',
  },
  {
    title: 'Created at',
    dataIndex: 'created',
  },
];

const dataUser = [
  {
    key: '1',
    name: 'cc747065-c8e3-4f25-aded-5316f8e59acf',
    created: 'Created',
  },
  {
    key: '2',
    name: 'cc747065-c8e3-4f25-aded-5316f8e31232',
    created: 'Created',
  },
];

export const ApiPage: React.FC = () => {
  const { isLoaded, userId } = useAuth();
  const apiKey = useSelector(apiKeySelector);
  const secret = useSelector(secretSelector);
  const [dataAPI, setDataApi] = useState<APIDataType[]>();
  const [dataClient, setDataClient] = useState<ClientDataType[]>();
  const [dataUser, setDataUser] = useState<UserDataType[]>();
  const [open, setOpen] = useState(false);


  const { data: clientsData } = useGetClientsQuery(null, {
    skip: !apiKey,
  });
  const { data: usersData } = useGetUsersQuery(null, {
    skip: !apiKey,
  });
  // const [getClients] = useGetClientsMutation();
  useEffect(() => {
    if (apiKey) {
      setDataApi([{ key: apiKey, name: apiKey, secret: secret }]);
    }
  }, [apiKey]);
  useEffect(() => {
    if (clientsData && clientsData.length > 0) {
      let clientDataTypeTmp: ClientDataType[] = [];
      clientsData?.forEach((clientData) => {
        clientDataTypeTmp.push({
          key: clientData.clientKey,
          organisation:
            clientData.organization ||
            clientData.customKey,
          email:
            clientData.email ||
            clientData.customKey,
          name:
            clientData.fullname ||
            clientData.customKey,
          created: clientData.createTime
            ? dayjs(clientData.createTime).format('YYYY-MM-DD HH:mm:ss')
            : dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        });
      });
      setDataClient(clientDataTypeTmp);
    }
  }, [clientsData]);
  useEffect(() => {
    if (usersData && usersData.length > 0) {
      let userDataTypeTmp: UserDataType[] = [];
      usersData?.forEach((userData) => {
        userDataTypeTmp.push({
          key: userData.userKey,
          name: userData.fullname || userData.customKey,
          created: userData.createTime
            ? dayjs(userData.createTime).format('YYYY-MM-DD HH:mm:ss')
            : dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          clientKey: userData.clientKey,
        });
      });
      setDataUser(userDataTypeTmp);
    }
  }, [usersData]);
  if (!isLoaded || !userId) {
    return null;
  }

const showDrawer = () => {
  setOpen(true);
};

const onClose = () => {
  setOpen(false);
};

  return (
    <>
      <Row style={{ margin: '0 0 16px 0' }}>
        <Col>
          <Title
            level={3}
            style={{
              margin: '0',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            API Keys
          </Title>
        </Col>
        <Col flex={'auto'} />
        <Col>
          <Space>
            <Tooltip title='Open API Reference'>
              <Button icon={<FontAwesomeIcon icon={faCode} />} onClick={showDrawer}>API</Button>
            </Tooltip>
            <Drawer title="API Reference" onClose={onClose} open={open} width={450}>
              <Space direction='vertical' style={{display: 'flex'}}>
              <Title level={5} style={{marginTop: 0}}>
                Create Client
              </Title>
              <Card style={{height: 400, overflow: 'auto', background: '#eaeef3'}}>
                    <CopyBlock
                      language="javascript"
                      text={`curl -X POST 'https://api.sendforsign.com/api/client' \\
-H 'Authorization: Bearer 123456789' \\
-H 'Content-Type: application/json' \\
-d '{ 
  "data": {
    "action": "create",
    "client": {
      "fullname": "John Johnson",
      "email": "john_johnson@mail.com",
      "organization": "Company Inc.",
      "customKey": "YOUR_COMBINATION",
          "users": [{
            "fullname": "John Johnson",
            "email": "john_johnson@mail.com", 
            "customKey": "YOUR_COMBINATION"
                  }]
              }
}'`}
                      codeBlock={false}
                      theme={monoBlue}
                      showLineNumbers={false}
                    />
                    </Card>
                    <Title level={5} >
                    Update Client
              </Title>
              <Card style={{height: 400, overflow: 'auto', background: '#eaeef3'}}>
                    <CopyBlock
                      language="javascript"
                      text={`curl -X POST 'https://api.sendforsign.com/api/client' \\
-H 'Authorization: Bearer 123456789' \\
-H 'Content-Type: application/json' \\
-d '{ 
  "data": {
    "action": "update",
    "client": {
      "clientKey": "CLIENT_KEY",
      "fullname": "John Johnson",
      "email": "john_johnson@mail.com",
      "organization": "Company Inc.",
      "customKey": "YOUR_COMBINATION"
              }
          }
}'`}
                      codeBlock={false}
                      theme={monoBlue}
                      showLineNumbers={false}
                    />
                    </Card>

                    <Title level={5} >
                    List of Clients
              </Title>
              <Card style={{height: 250, overflow: 'auto', background: '#eaeef3'}}>
                    <CopyBlock
                      language="javascript"
                      text={`curl -X POST 'https://api.sendforsign.com/api/client' \\ 
-H 'Authorization: Bearer 123456789' \\
-H 'Content-Type: application/json' \\
-d '{ 
  "data": {
    "action": "list"
          }
}'`}
                      codeBlock={false}
                      theme={monoBlue}
                      showLineNumbers={false}
                    />
                    </Card>
              </Space>
            </Drawer>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columnsAPI}
        dataSource={dataAPI}
        bordered
        expandable={{
          expandedRowRender: () => (
            <Table
              columns={columnsClient}
              dataSource={dataClient}
              pagination={false}
              expandable={{
                expandedRowRender: (record: ClientDataType) => {
                  debugger;
                  const userFilter = dataUser.filter(
                    (data) => data.clientKey === record.key
                  );
                  return (
                    <Table
                      columns={columnsUser}
                      dataSource={userFilter}
                      pagination={false}
                    />
                  );
                },
              }}
            />
          ),
        }}
      />
    </>
  );
};
